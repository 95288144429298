export default {
  props: {
    timeData: {
      type: Array,
      "default": [{
        date: '2025年',
        content: ['荣登《英国医学杂志》（BMJ，IF=93.6）：该研究由上海中山医院内分泌科李小英、李晓牧教授团队、南方医科大学南方医院内分泌科张惠杰教授团队、诺特健康共同完成，诺特全程担纲TLC生活方式干预重任！研究证实：超重/肥胖2型糖尿病患者可通过药物联合TLC生活方式干预实现缓解及停药。']
      }, {
        date: '2024年',
        content: ['“诺特减重法”荣获中国疾控中心慢病中心体重管理优秀案例。', '诺特健康获批数字医疗证书。', '诺特健康联合近百位内分泌、营养学及健康管理学的临床专家，共同撰稿并发表了中国版GLP-1药物结合生活方式干预减重专家共识--《胰高糖素样肽-1受体激动剂类药物结合生活方式干预减重专家共识2024版》，科学指导临床实践工作。', '诺特健康联合申报及技术支持项目“5G+医疗健康应用试点项目”获国家工业和信息化部、国家卫生健康委员会全国优秀项目和通过验收项目。']
      }, {
        date: '2023年',
        content: ['医生健康管理工作室上线，帮助医生打造个人品牌，管理患者，协助科研。', '整体办公面积5万多平方米的诺特健康大厦（多功能医疗健康管理中心+产研一体数字化工厂）破土动工。', '截止2023年，诺特健康承接了近10个国家级科研项目。', '参与建立行业标准/共识3个。', '获得发明专利/个软件著作权/专业设备资质21个。']
      }, {
        date: '2022年',
        content: ['协助肥胖慢病“5G+三早健康管理”应用试点项目在近百家医院落地开展，并建立该项目实施标准。', '诺特联合南方医科大学南方医院内分泌科开展肥胖干预研究，荣登《新英格兰医学杂志》（IF=176.079），诺特健康是中国第一家出现在国际顶级医学杂志上的生活方式干预机构。']
      }, {
        date: '2021年',
        content: ['参与国家卫健委、工信部《关于5G+医疗健康应用试点项目》。', '4月 在国家体重管理行业获颁互联网医院资质。', '由“体重管理”延伸至“TLC-治疗性生活方式干预”，以聚焦肥胖慢病管理为核心，打造“体重管理+针对特殊疾病的干预措施+建立健康记忆”的全流程健康管理，同时在解决方案中使用“医疗级营养素”。']
      }, {
        date: '2020年',
        content: ['推出互动式智能化体重管理体系（OIP智能管理），覆盖更多超重/肥胖人群。']
      }, {
        date: '2019年',
        content: ['全国直营分中心达34家，覆盖全中国99.74%的人口（港、澳、台、西藏自治区除外）。', '成立“超重/肥胖体重管理专家委员会”。', '成立诺特健康管理省级高新技术企业研究开发中心。']
      }, {
        date: '2018年',
        content: ['参加科技部2018国家重点研发计划。', '参与《超重或肥胖人群体重管理规范》团体标准制定。', '中国营养学会“十佳体重管理中心”。']
      }, {
        date: '2017年',
        content: ['举办“诺特健康（第十一届）青少年减肥夏令营”，CCTV全程跟踪报道。', '全自动生产车间投入使用，保障质量与安全。']
      }, {
        date: '2016年',
        content: ['获评“G20峰会 · 杭州名片”，向世界展示“杭州名片”的互联网健康特色。', '联合中华健康管理学杂志社举办《2016年全国肥胖与体重管理学术会议（上海）》，邀请15位国际知名体重管理专家，推出“诺特中国体重管理计划”。']
      }, {
        date: '2015年',
        content: ['体重管理干预APP——“体重管家”发布运营，诺特体重管理服务迈入移动互联网时代。']
      }, {
        date: '2012年',
        content: ['承担国家级十二五科研项目。', '推出标准化体重管理干预流程--“TLC-诺特科学减重五步法。”']
      }, {
        date: '2010年',
        content: ['成为中华医学会健康管理分会“非药物干预体重管理示范基地”。', '在中国健康促进基金会设立“非药物干预体重管理专项基金”，旨在支持临床开展专业减重项目。', '全新研发的NUGGET代餐营养棒、能量阻断咖啡/纤冲上市，引领中国淀粉酶阻断剂市场蓬勃发展，畅销至今。']
      }, {
        date: '2009年',
        content: ['帮助小娅儿成功减重102斤，备受全国包括CCTV、浙江卫视、都市快报等多家媒体报道。', '开始向全国发展，在上海、南京等地增设诺特健康管理分中心。 ']
      }, {
        date: '2006年',
        content: ['浙大诺特营养中心进行股改，浙江诺特健康科技股份有限公司成立，简称“诺特健康”。']
      }, {
        date: '2005年',
        content: ['举办第一届儿童青少年减重夏令营，营员规模超150人。']
      }, {
        date: '2003年',
        content: ['浙大诺特营养中心成立，创立“诺特减重法 Nutriease TLC”。']
      }]
    }
  }
};