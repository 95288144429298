import "core-js/modules/web.timers.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.unshift.js";
import Nav from '@/components/Nav';
export default {
  data: function data() {
    return {
      // swiperIndex: 1,
      //增加轮播图后，其对应的css需要去计算并修改item-${swiperClass[index]}的bottom的值，最后一个图最大为90px，第一张为0，第2张默固定从35px开始，中间的轮播图的bottom的值需要均分：（90-35）/（轮播图个数-2）
      swiperList: [require('@/assets/images/index1/swiper-1.png'), require('@/assets/images/index1/swiper-2.png'), require('@/assets/images/index1/swiper-3.png'), require('@/assets/images/index1/swiper-4.png'), require('@/assets/images/index1/swiper-5.png'), require('@/assets/images/index1/swiper-6.png'), require('@/assets/images/index1/swiper-7.png')],
      swiperClass: [],
      // activeNav: '',
      timer: null,
      // swiperItemBottom: [null, null, null, null, null, null, null],
      // isInit: false,
      opacity: 0,
      innerHeight: 0,
      minHeight: null,
      paddingBottom: null,
      bottom: null
    };
  },
  components: {
    Nav: Nav
  },
  computed: {},
  methods: {
    initSwiper: function initSwiper() {
      var _this = this;
      this.opacity = 0;
      var index = this.swiperList.length;
      var loop = function loop() {
        if (index > 0) {
          setTimeout(function () {
            _this.swiperClass.push(index);
            index--;
            loop();
          }, 200);
        }
      };
      loop();
      setTimeout(function () {
        _this.opacity = 1;
        _this.timer = setInterval(function () {
          var num = _this.swiperList.length - 1;
          var last = _this.swiperClass[num];
          _this.swiperClass.length = num;
          _this.swiperClass.unshift(last);
        }, 2500);
      }, 800);
    }
  },
  mounted: function mounted() {
    this.initSwiper();
  },
  created: function created() {
    //原生浏览器底部状态栏可能会挡住页面
    var clientHeight = document.body.clientHeight || 0;
    var innerHeight = window.innerHeight || 0;
    var diff = clientHeight - innerHeight;
    //超过合理区间重置
    var height = diff < 100 && diff > -100 ? diff : 0;
    this.minHeight = "calc(100vh - ".concat(50 + height, "px)");
    this.paddingBottom = "".concat(50 + height, "px");
    this.bottom = "".concat(height + 17, "px");
  },
  beforeDestroy: function beforeDestroy() {
    if (this.timer) clearInterval(this.timer);
  }
};