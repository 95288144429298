import "core-js/modules/es.array.for-each.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.find.js";
import { secondRoutes } from '@/router';
export default {
  data: function data() {
    return {
      navList: [{
        name: '品牌介绍',
        url: '/about/introduction'
      }, {
        name: '发展历程',
        url: '/about/develop'
      }, {
        name: '新闻动态',
        url: '/about/news'
      }, {
        name: '加入我们',
        url: '/about/join'
      }],
      currentRoute: {},
      bannerUrl: '',
      pageTitle: ''
    };
  },
  watch: {
    $route: {
      handler: function handler(value) {
        this.bannerUrl = value.meta.banner;
        this.pageTitle = value.meta.title;
      },
      immediate: true
    }
  },
  methods: {
    //有的页面内部还会切换banner，留一个方法手动设置
    setBanner: function setBanner(url) {
      this.bannerUrl = url;
    },
    //跳转微信客服
    toService: function toService() {
      window.location.href = 'https://work.weixin.qq.com/kfid/kfc9d35f8f6f924c917';
    }
  },
  beforeRouteEnter: function beforeRouteEnter(to, from, next) {
    var currentRoute;
    secondRoutes.children.forEach(function (element) {
      var currentChild = element.children.find(function (item) {
        return item.path == to.path;
      });
      if (currentChild) {
        currentRoute = {
          titlePic: element.meta.titlePic,
          navList: element.children
        };
      }
    });
    next(function (vm) {
      vm.currentRoute = currentRoute;
    });
  },
  mounted: function mounted() {}
};